import React from 'react';
import Publications from '../components/Publications';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import ebook from "../images/ebook.webp"

const posts = [
  {
    title: 'Cloud-Based Generative AI: 10 Ways to Transform Business',
    href: '/blogs/cloud-based-generative-ai-10-ways-to-transform-business',
    category: { name: 'Article', href: '#' },
    description:"Cloud-based generative AI is changing how companies work, innovate, and compete. Here's a quick look at 10 key ways it's transforming business",
    date: 'Aug 23, 2024',
    datetime: '2024-08-23',
    imageUrl:
      'https://mars-images.imgix.net/seobot/c4scale.com/66c48414216c71a6e5056fc9-660d3b05536f713e09f6d98b93c10cd0.png?auto=compress',
    readingTime: '4 min',
  },
  {
    title: 'The Generative AI adoption framework to maximize Return On Investments (ROI)',
    href: '/blogs/gen-ai-adoption-playbook-framework',
    category: { name: 'Article', href: '#' },
    description:
      'This blog provides a practical approach and a playbook to adopt Generative AI within your enterprises and maximizing the ROI',
    date: 'May 3, 2024',
    datetime: '2024-05-03',
    imageUrl:
      'https://res.cloudinary.com/durti17dk/image/upload/v1716964856/blog-assets/AI_adoption_auuqco_g41k8a.webp',
    readingTime: '4 min',
  },
  {
    title: 'Top Privacy Concerns in adopting GenAI & practical tips to mitigate them',
    href: '/blogs/genai-adoption-mitigating-risks',
    category: { name: 'Article', href: '#' },
    description:
      'ChatGPT is powerful but your data is sensitive & critical.',
    date: 'Feb 20, 2024',
    datetime: '2024-02-20',
    imageUrl:
      'https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/Privacy-Concerns-_eewstl_a4dbk0.webp',
    readingTime: '5 min',
  },
  {
    title: 'The Data Quality Jigsaw: Why & How',
    href: '/blogs/the-data-quality-jigsaw',
    category: { name: 'Article', href: '#' },
    description:
      'This blog underscores the pivotal importance of data quality in driving sound decision-making and maximizing the ROI of data teams within organizations.',
    date: 'October 2, 2023',
    datetime: '2023-10-02',
    imageUrl:
      'https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/Data_quality_Main_Picture_fjmdrb_q6ov0c.webp',
    readingTime: '8 min',
  },
  {
    title: 'Migrating billion+ documents from Elastic Search to AWS S3',
    href: '/blogs/elastic-search-to-s3-data-migration',
    category: { name: 'Article', href: '#' },
    description:
      'Blog provides simple solution to move enormous volumes of data stashed in ES to S3',
    date: 'July 21, 2023',
    datetime: '2023-07-21',
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
    readingTime: '6 min',
  },
]

export default function Example() {
  return (
    <>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-[0%]" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-semibold text-gray-900 sm:text-4xl">Blogs & Insights</h2>
          </div>
          <div className="mt-12 max-w-5xl mx-auto grid gap-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={ebook}  alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        <Link to="/cdo-handbook-7-ways-to-cut-down-data-costs" className="hover:underline">
                          E-Book
                        </Link>
                      </p>
                      <Link to="/cdo-handbook-7-ways-to-cut-down-data-costs" className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">CDO's Handbook - 7 Ways To Cut Down Data Costs</p>
                        <p className="mt-3 text-base text-gray-600">A one-stop guide that provides a comprehensive & strategic ways to cut down data costs in your organisation from our expertise handing Data @planet scale</p>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center ">
                      <div >
                        <div className="flex space-x-1 text-sm text-gray-600 items-center justify-center" >
                        <Link
                to="/cdo-handbook-7-ways-to-cut-down-data-costs"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white"
              >
                Download
              </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {posts.map((post) => (
                <div key={post.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        <Link to={post.category.href} className="hover:underline">
                          {post.category.name}
                        </Link>
                      </p>
                      <Link to={post.href} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                        <p className="mt-3 text-base text-gray-600">{post.description}</p>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div >
                        <div className="flex space-x-1 text-sm text-gray-600">
                          <time dateTime={post.datetime}>{post.date}</time>
                          <span aria-hidden="true">&middot;</span>
                          <span>{post.readingTime} read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
