import React, { useState, useEffect } from 'react';
import { Box, Button, LinearProgress,Stack } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { Link } from 'gatsby';

const reviewsData = [
    {
      reviewText:
        'Rigor, visibility, and transparency. That is C4Scale. C4Scale puts everyone on the same page.',
      author: 'John Doe',
      post:"Product Analytics Manager",
      image:"https://assets.website-files.com/6171016af5f2c517ec1ac76c/647f87e5aef204be56aeee6c_Marc%20Stone%20Image.png",
      url:"#",
      companylogo: 'https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373d5c03c5d3a125fa293af_Logo%20box-1.png',
    },
    {
      reviewText:
        'Our team is very happy with the way we are running experiments with C4Scale across front-end, back-end and Machine Learning use cases.',
      author: 'John Doe',
      post:"‍ML Engineering Manager",
      image:"https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373ec789113dd4d34491443_image%20placeholder-2.png",
      url:"#",
      companylogo: 'https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373d5c0bb8f6f7bc17e1761_Logo%20box-2.png',
    },
    {
      reviewText:
        'After switching to C4Scale, our Product Managers are spending 50% less time making dashboards and debugging issues, which leaves more time to develop the features our users want.',
      author: 'John Doe',
      post:"‍Product Data Science Manager",
      image:"https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373ec776ca49d14a9f7e8a4_image%20placeholder-1.png",
      companylogo: 'https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373d5c02f0a025d5b11d9be_Logo%20box-3.png',
      url:"#",
    },
    {
      reviewText:
        'C4Scale enables us to get insights that wouldn’t be possible in previous tools. It’s a whole new generation of A/B platform.',
      author: 'John Doe',
      post:"Head Of Data & Analytics",
      image:"https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373ec78b3de9cf62115f9d3_image%20placeholder-3.png",
      url:"#",
      companylogo: 'https://assets.website-files.com/6171016af5f2c517ec1ac76c/6373d5c019d572fa93287c1b_Logo%20box-4.png',
    },
  ];
  

const Reviews = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleReviewChange = (index) => {
    setCurrentReviewIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLoading(true);
      setProgress(0);
  
      const steps = 60;
      const increment = 100 / steps;
  
      const progressInterval = setInterval(() => {
        setProgress((prevProgress) => prevProgress + increment);
      }, 100);
  
      setTimeout(() => {
        clearInterval(progressInterval);
        setCurrentReviewIndex((prevIndex) =>
          prevIndex === reviewsData.length - 1 ? 0 : prevIndex + 1
        );
        setIsLoading(false);
      }, 6500);
    }, 7000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);
  

  return (
    <>
    <Stack justifyContent="center" alignItems="center" margin="15px 0px" gap="40px">
    <h1 className='text-4xl font-bold mt-4 mb-8'>Loved by our customers</h1> 
        <Stack sx={{background:"#f5f5ff",borderRadius:"8px",padding:"35px 45px"}} justifyContent="center"  width={"62vw"} gap="15px" >
        <Stack sx={{justifyContent:"space-between"}} direction="row">
        <h1 className='text-xl font-semibold mt-4 min-h-[90px]' >{reviewsData[currentReviewIndex].reviewText}</h1>  
        <FormatQuoteIcon sx={{fontSize:"50px"}}/>
        </Stack>
        <Stack justifyContent="space-between" direction="row" alignItems="flex-end">
        <Stack direction="row" gap="20px" alignItems="flex-start" mt="20px">
        <Box component="img" src={reviewsData[currentReviewIndex].image} alt="company logo" sx={{width:"80px",height:"80px"}}/>
        <Stack>
        <h1 className='text-xl font-semibold mt-2'>{reviewsData[currentReviewIndex].author}</h1>
        <h1 className='text-lg'>{reviewsData[currentReviewIndex].post}</h1>
        </Stack>
        </Stack>
        <Link to={reviewsData[currentReviewIndex].url}>
        <Stack gap="10px" direction="row" alignItems="center" sx={{cursor:"pointer"}}>
        <h1 className='text-md font-semibold'>Read the case study </h1><ArrowCircleRightRoundedIcon sx={{fontSize:"24px",color:"#4f46e5"}}/>   
        </Stack>
        </Link>
        </Stack>
         
      
      </Stack>
      <Stack justifyContent="space-between" alignItems="center" direction="row" mb="80px" gap="30px">
        {reviewsData.map((review, index) => (
          <Button
            key={index}
            variant="contained"
            disableElevation
            sx={{background:"transparent",padding:" 6px 0px",marginBottom: "10px",'&:hover': {
              background:"none"
              }}}
            onClick={() => handleReviewChange(index)}
            
          >
            <Box component="img" src={reviewsData[index].companylogo} alt="company logo" sx={{width:"150px",height:"75px",marginBottom:"7px",filter: index === currentReviewIndex ? "null" : 'grayscale(1)',objectFit:"contain"}}/>
            {isLoading && currentReviewIndex === index && (
              <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                borderRadius: '10px',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#7964d3',
                },
              }}
            />
            )}
          </Button>
        ))}
      </Stack>
      </Stack>

      
 
    </>
  );
};

export default Reviews;
